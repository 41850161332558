import { Component, VERSION, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { Result } from '@zxing/library';

import { Drug } from 'src/app/models/drug.model';
import { DrugsService } from 'src/app/services/drugs.service';
import { MedocsService } from 'src/app/services/medocs.service';

@Component({
  selector: 'app-drug-scan',
  templateUrl: './drug-scan.component.html',
  styleUrls: ['./drug-scan.component.sass']
})
export class DrugScanComponent implements OnInit {

  ngVersion = VERSION.full;

  @ViewChild('scanner', { static: true })
  scanner: ZXingScannerComponent;

  hasDevices: boolean;
  hasPermission: boolean;
  qrResultString: string;
  qrResult: Result;
  noResults: boolean = false;

  availableDevices: MediaDeviceInfo[];
  currentDevice: MediaDeviceInfo = null;

  constructor(private router: Router, private drugService: DrugsService, private medocsService: MedocsService) { }

  ngOnInit(): void {
    this.scanner.camerasFound.subscribe((devices: MediaDeviceInfo[]) => {
      // this.hasDevices = true;
      this.availableDevices = devices;

      // selects the devices's back camera by default
      for (const device of devices) {
        if (/back|rear|environment/gi.test(device.label)) {
          this.scanner.device = device;
          this.currentDevice = device;
          this.scanner.updateVideoInputDevices();
          break;
        }
      }
    });

    // you can observe if there's devices
    this.scanner.hasDevices.subscribe((x: boolean) => this.hasDevices = x);
    // or you can manually check if the component found them
    this.scanner.scanComplete.subscribe((x: Result) => this.qrResult = x);
    this.scanner.permissionResponse.subscribe((x: boolean) => this.hasPermission = x);
  }

  displayCameras(cameras: MediaDeviceInfo[]) {
    this.availableDevices = cameras;
  }

  handleQrCodeResult(resultString: string) {
    // Décode le data_matrix scanné
    const scannedData = /010(\d{13})17(\d{6})10(\w*)/gm.exec(resultString.trim());
    // Construit la date suivant le schéma YYYY MM DD
    const dateArray = /(\d{2})(\d{2})(\d{2})/.exec(scannedData[2]);
    const date = new Date(2000 + parseInt(dateArray[1]), parseInt(dateArray[2]), parseInt(dateArray[3]));
    // Créer le médicament
    this.medocsService.get(parseInt(scannedData[1])).subscribe(medoc => {
      if (medoc !== null) {
        const drug = new Drug(medoc['name'], date, scannedData[3], scannedData[1]);
        if (drug.isValid()) {
          this.drugService.create(drug);
          this.router.navigate(['/drugs/list']);
        }
      } else {
        this.noResults = true;
      }
    });

  }

  onDeviceSelectChange(selected: string) {
    const device = this.availableDevices.find(x => x.deviceId === selected);
    this.currentDevice = device || null;
  }
}
