import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  private storage;

  constructor() {
    this.storage = window.localStorage;
  }

  getHomePage(): boolean {
    return this.storage.homepageSettings ? (/true/i).test(this.storage.homepageSettings) : false;
  }

  saveHomePage(homepage: boolean) {
    this.storage.homepageSettings = homepage;
  }

  getCameraScan(): boolean {
    return this.storage.cameraScanSettings ? (/true/i).test(this.storage.cameraScanSettings) : false;
  }

  saveCameraScan(cameraScan: boolean) {
    this.storage.cameraScanSettings = cameraScan;
  }
}
