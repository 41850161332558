<h2>Liste</h2>
<div *ngIf="getListLength() === 0">
  <p>Votre liste est vide. Pour commencer, ajoutez un médicament à votre liste.</p>
  <img src="assets/undraw_no_data_qbuo.svg" class="image-responsive" alt="No Data">
</div>

<div *ngIf="getListLength() > 0">
  <mat-list>
    <mat-list-item *ngFor="let drug of drugList; let last = last" [style.border-bottom]="last ? '' : '1px dashed #ddd'">
      <mat-icon matListIcon color="warn">{{ isExpirationDatePassed(drug.expirationDate) ? 'warning' : '' }}</mat-icon>
      <h3 mat-line>{{drug.name}}</h3>
      <p mat-line>Périmé le {{drug.expirationDate | date: 'dd/MM/yyyy'}}</p>
      <p *ngIf="drug.lot" mat-line>Lot n° {{drug.lot}}</p>
      <p *ngIf="drug.cip" mat-line>CIP: {{drug.cip}}</p>
      <button *ngIf="false" mat-icon-button (click)="editDrug(drug.id)">
        <mat-icon>edit</mat-icon>
      </button>
      <button mat-icon-button (click)="removeDrug(drug.id)">
        <mat-icon>delete</mat-icon>
      </button>
    </mat-list-item>
  </mat-list>
</div>

<span class="bottom-button">
  <button mat-fab (click)="navigateDrugAdd()">
    <mat-icon>add</mat-icon>
  </button>
</span>