<h2>
  Ajouter avec le scanner
</h2>
<div [hidden]="!hasDevices || !hasPermission || noResults">
  <zxing-scanner #scanner [(device)]="currentDevice" (scanSuccess)="handleQrCodeResult($event)"
    [formats]="['DATA_MATRIX']" [tryHarder]="true"></zxing-scanner>
</div>
<div [hidden]="hasDevices || hasPermission">
  <p>Vous ne pouvez pas scanner de produit.. Il faut ajouter le médicament à la main.</p>
</div>

<div [hidden]="!noResults">
  <p>Désolé, le scan du produit n'a pas fonctionné .. Il faudra le rajouter à la main.</p>
</div>
<div class="align-right form-buttons">
  <button mat-raised-button routerLink="/drugs/list">Annuler</button>
  <button mat-raised-button routerLink="/drugs/add">Ajout Manuel</button>
</div>