import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class MedocsService {

  medocUrl = `${environment.apiUrl}/drugs`;

  constructor(private http: HttpClient) {
  }

  get(CIP: Number) {
    return this.http.get(`${this.medocUrl}/${CIP}`);
  }

}
