<h2> Paramètres</h2>
<img src="assets/undraw_settings_ii2j.svg" class="image-responsive" alt="No Data">
<div>
    <mat-slide-toggle [checked]="homepage" (change)="toggleHomepage($event)">
        Afficher la liste au démarrage
    </mat-slide-toggle>
    <!--
        Desactivation de la caméra
    <br />
    <mat-slide-toggle [checked]="cameraScan" (change)="toggleCameraScan($event)">
        Autoriser le scan via la camera
    </mat-slide-toggle>
-->
</div>
<div *ngIf="firstLaunch" [hidden]="true">
    <zxing-scanner></zxing-scanner>
</div>