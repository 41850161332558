import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/main/home/home.component';
import { NotFoundComponent } from './components/main/not-found/not-found.component';
import { DrugListComponent } from './components/drugs/drug-list/drug-list.component';
import { DrugAddComponent } from './components/drugs/drug-add/drug-add.component';
import { SettingsComponent } from './components/user/settings/settings.component';
import { AboutComponent } from './components/main/about/about.component';
import { DrugScanComponent } from './components/drugs/drug-scan/drug-scan.component';

const routes: Routes = [
  { path: '', component: HomeComponent },

  { path: 'drugs/list', component: DrugListComponent },
  { path: 'drugs/add', component: DrugAddComponent },
  { path: 'drugs/scan', component: DrugScanComponent },
  { path: 'drugs/edit/:id', component: DrugAddComponent },

  { path: 'settings', component: SettingsComponent },
  { path: 'about', component: AboutComponent },

  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
