import { Component, OnInit } from '@angular/core';
import { DrugsService } from 'src/app/services/drugs.service';
import { Drug } from 'src/app/models/drug.model';
import { Router, ActivatedRoute } from '@angular/router';
import { DateAdapter } from '@angular/material/core';
import { isNumber } from 'util';

@Component({
  selector: 'app-drug-add',
  templateUrl: './drug-add.component.html',
  styleUrls: ['./drug-add.component.sass']
})
export class DrugAddComponent implements OnInit {

  private formSent: boolean = false;
  private validated: boolean = false;
  public drug: Drug = null;

  constructor(private router: Router, private route: ActivatedRoute, private dateAdapter: DateAdapter<Date>, private drugService: DrugsService) {
    this.dateAdapter.setLocale('fr');
  }

  ngOnInit() {
    let id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.drug = this.drugService.readOne(id);
    }
  }

  isFormSent(): boolean {
    return this.formSent;
  }

  nameCheck(name: string): boolean {
    return new Drug(name).isNameValid();
  }

  lotCheck(lot: string): boolean {
    return new Drug(null, null, lot).isLotValid();
  }

  cipCheck(cip: string): boolean {
    return new Drug(null, null, null, cip).isCIPValid();
  }

  dateCheck(date: string): boolean {
    const expirationDate = new Date(date.split('/').reverse().join('/'));
    return new Drug(null, expirationDate).isExpirationDateValid();
  }

  createDrug(name: string, expirationDate: string, lot: string, cip: string) {
    this.formSent = true;
    const date = new Date(expirationDate.split('/').reverse().join('/'));
    const drug = new Drug(name, date, lot, cip);
    if (this.drug === null) {
      if (drug.isExpirationDateValid() && drug.isNameValid()) {
        this.drugService.create(drug);
        this.validated = true;
      }
    } else {
      this.drug.name = name;
      this.drug.lot = lot;
      this.drug.cip = cip;
      this.drug.expirationDate = date;
      if (this.drug.isExpirationDateValid() && this.drug.isNameValid()) {
        this.drugService.update(this.drug);
        this.validated = true;
      }
    }
    if (this.validated) {
      this.router.navigate(['/drugs/list']);
    }
  }

}
