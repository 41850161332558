<h2>Ajouter</h2>

<form class="form">
  <mat-form-field class="form-full-width">
    <input #name matInput placeholder="Nom *" value="{{drug ? drug.name : ''}}">
  </mat-form-field>
  <div *ngIf="isFormSent() && !nameCheck(name.value)" class="form-error">
    Le nom est obligatoire
  </div>

  <mat-form-field class="form-full-width">
    <input #expirationDate matInput [matDatepicker]="picker" placeholder="Date d'expiration *"
      value="{{drug ? drug.expirationDate : ''}}">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
  <div *ngIf="isFormSent() && !dateCheck(expirationDate.value)" class="form-error">
    La date d'expiration est obligatoire
  </div>

  <mat-form-field class="form-full-width">
    <input #lot matInput placeholder="Lot" value="{{drug ? drug.lot : ''}}">
  </mat-form-field>

  <mat-form-field class="form-full-width">
    <input #cip matInput placeholder="CIP" value="{{drug ? drug.cip : ''}}">
  </mat-form-field>
</form>

<p>Les données avec un astérix (*) sont obligatoires.</p>
<!--
  Désactivation de la camera
<p>Conseil : Vous pouvez activer l'ajout par scan dans les paramètres.</p>
-->

<div class="align-right form-buttons">
  <button mat-raised-button routerLink="/drugs/list">Annuler</button>
  <button mat-raised-button color="primary"
    (click)="createDrug(name.value, expirationDate.value, lot.value, cip.value)">{{drug ? 'Editer' : 'Ajouter'
    }}</button>
</div>