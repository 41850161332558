import { Injectable } from '@angular/core';
import { Drug } from '../models/drug.model';

@Injectable({
  providedIn: 'root'
})
export class DrugsService {

  private storage;

  constructor() {
    this.storage = window.localStorage;
  }

  create(drug: Drug): boolean {
    let drugList = this.read();
    const length = drugList.length;
    if (!drugList) {
      drugList = Array<Drug>();
    }
    drugList.push(drug);
    this.storage.drugList = JSON.stringify(drugList);
    return length + 1 === drugList.length;
  }

  read(): Array<Drug> {
    let drugList = this.storage.drugList;
    return drugList ? JSON.parse(drugList) : Array<Drug>();

  }

  readOne(id: string): Drug {
    return this.read().find((drug) => {
      return drug.id === id;
    });
  }

  update(drug: Drug): Drug {
    let drugList = this.read();
    drugList[drugList.map(element => { return element.id }).indexOf(drug.id)] = drug;
    this.storage.drugList = JSON.stringify(drugList);
    return drug;
  }

  delete(id: string): boolean {
    let drugList = this.read();
    let spliced = drugList.splice(drugList.map(element => { return element.id }).indexOf(id), 1);
    this.storage.drugList = JSON.stringify(drugList);
    return spliced.length - 1 === drugList.length;
  }
}
