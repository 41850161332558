import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';


import { AppRoutingModule } from './app-routing.module';
import { AppMaterialModule } from './app-material.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';

import { ZXingScannerModule } from '@zxing/ngx-scanner';

import { AppComponent } from './app.component';
import { HeaderComponent } from './components/main/header/header.component';
import { FooterComponent } from './components/main/footer/footer.component';
import { HomeComponent } from './components/main/home/home.component';
import { NotFoundComponent } from './components/main/not-found/not-found.component';
import { DrugListComponent } from './components/drugs/drug-list/drug-list.component';
import { DrugAddComponent } from './components/drugs/drug-add/drug-add.component';
import { DrugScanComponent } from './components/drugs/drug-scan/drug-scan.component';

import { environment } from '../environments/environment';
import { SettingsComponent } from './components/user/settings/settings.component';
import { AboutComponent } from './components/main/about/about.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    NotFoundComponent,
    DrugListComponent,
    DrugAddComponent,
    SettingsComponent,
    AboutComponent,
    DrugScanComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AppMaterialModule,
    BrowserAnimationsModule,
    ZXingScannerModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
