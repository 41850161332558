import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Drug } from 'src/app/models/drug.model';
import { DrugsService } from 'src/app/services/drugs.service';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-drug-list',
  templateUrl: './drug-list.component.html',
  styleUrls: ['./drug-list.component.sass']
})
export class DrugListComponent implements OnInit {

  private drugList: Array<Drug> = Array<Drug>();

  constructor(private router: Router, private drugsService: DrugsService, private settingsService: SettingsService) { }

  ngOnInit() {
    this.getDrugList();
  }

  isExpirationDatePassed(date: Date) {
    return Date.now() - new Date(date).getTime() > 0;
  }

  getListLength() {
    return this.drugList.length;
  }

  getDrugList() {
    this.drugList = this.drugsService.read();
  }

  editDrug(id: string) {
    this.router.navigate(['/drugs/edit', id]);
  }

  removeDrug(id: string) {
    this.drugsService.delete(id);
    this.getDrugList();
  }

  navigateDrugAdd() {
    if (this.settingsService.getCameraScan()) {
      this.router.navigate(["/drugs/scan"]);
    } else {
      this.router.navigate(["/drugs/add"]);
    }
  }
}
