<mat-toolbar color="primary">
  <button mat-icon-button routerLink="/">
    <mat-icon>local_hospital</mat-icon>
  </button>
  <span routerLink="/">Medocs</span>
  <button mat-icon-button [mat-menu-trigger-for]="menu">
    <mat-icon>more_vert</mat-icon>
  </button>
</mat-toolbar>
<mat-menu x-position="before" #menu="matMenu">
  <button mat-menu-item routerLink="/about">A propos</button>
  <button mat-menu-item routerLink="/settings">Paramètres</button>
</mat-menu>