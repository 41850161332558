import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.sass']
})
export class HomeComponent implements OnInit {

  constructor(private router: Router, private settingsService: SettingsService) { }

  ngOnInit() {
    if (this.settingsService.getHomePage()) {
      this.router.navigate(['/drugs/list']);
    }
  }

}
