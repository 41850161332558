<h2>A propos</h2>

<p class="align-justify">
  Medocs est une application de gestion de vos médicaments.
  L'application vous permet de scanner les médicaments que vous avez chez vous et de faire attention aux dates de
  péremption.
</p>
<img src="assets/undraw_medicine_b1ol.svg" class="image-responsive" alt="Medicine">
<p class="align-justify">
  De cette façon, vous saurez directement si vous avez encore du paracétamol chez vous au lieu d'en racheter à la
  pharmacie !
</p>
<p class="align-justify">
  Si vous avez des questions ou des remarques n'hésitez pas à nous envoyer un message à <a
    href="mailto:alfred@medocs.app">alfred@medocs.app</a> !
</p>