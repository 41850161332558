export class Drug {
    public id: string;
    public name: string;
    public expirationDate: Date;
    public lot: string;
    public cip: string;

    constructor(name: string = '', expirationDate: Date = new Date(), lot: string = '', cip: string = '', id: string = null) {
        if (id === null) {
            this.id = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
        }
        this.name = name;
        this.expirationDate = expirationDate;
        this.lot = lot;
        this.cip = cip;
    }

    public isValid(): boolean { return this.isCIPValid() && this.isExpirationDateValid() && this.isNameValid() && this.isLotValid(); }

    public isExpirationDateValid(): boolean { return !isNaN(this.expirationDate.getTime()); }
    public isCIPValid(): boolean { return (this.cip.length === 7 || this.cip.length === 13) && (typeof parseInt(this.cip) === 'number'); }
    public isNameValid(): boolean { return this.name.length >= 4; }
    public isLotValid(): boolean { return this.lot.length >= 4; }

}